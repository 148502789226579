import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, colors } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Logo } from "../../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Navbar = () => {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);
  const openMenu = () => {
    setSidebarVisibility(!isSidebarVisible);
  };

  useGSAP(() => {
    gsap.from(".logo", {
      y: -30,
      opacity: 0,
      duration: 1,
      delay: 1,
    });
  });

  useGSAP(() => {
    gsap.from(".rightsideoption", {
      y: -30,
      opacity: 0,
      duration: 1,
      stagger: 0.3,
      delay: 1,
    });
  });
  const history = useHistory();
  const webStyle = {
    container: {
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      padding: "10px 0 0 0",
      height: "auto",
      width: "100%",
      zIndex: "1111",
    },
    MenuButtonStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "white",
      border: "none",
      color: "black",
    },
    logo: {
      height: "80px",
      cursor: "pointer",
      paddingBottom: "8px",
    },
    options: {
      padding: "15px",
      fontWeight: 600,
      borderBottom: "1px solid grey",
      cursor: "pointer",
      fontSize:"20px"
    },
    optionsDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    HeadingOption: {
      marginRight: "40px",
      cursor: "pointer",
      color: "black",
      fontSize: "20px",
    },
    itemImage: {
      width: "40px",
      height: "40px",
      marginRight: "10px",
    },
  };
  const handleContact = () => {
    window.location.href = "tel:8087878181";
  };
  // const handleEmail = () => {
  //   window.location.href = "mailto:@gmail.com";
  // };
  const handleHomeclick = () => {
    setSidebarVisibility(false);
    history.push("/");
  };
  const handleAboutUsclick = () => {
    setSidebarVisibility(false);
    history.push("/Aboutus");
  };
  const HandleHomeCleaningClick = () => {
    setSidebarVisibility(false);
    history.push("/Homecleaning");
  };

  const [openSection, setOpenSection] = useState(null);

  const handleSectionClick = (sectionName) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === sectionName ? null : sectionName
    );
  };
  const HandleWhatsApp = () => {
    const phoneNumber = "+918087878181";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };
  return (
    <div
      className="NavbarContainer"
      style={{
        ...webStyle.container,
        position: "fixed",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          padding: "0 40px",
        }}
        className="navbarLogoContainer"
      >
        <img
          onClick={() => history.push("/")}
          src={Logo}
          style={webStyle.logo}
          className="logo"
        />
      </div>
      <Box style={{ ...webStyle.optionsDiv, padding: "0 40px",margin:"10px 0" }}>
      <a href="#" style={{ textDecoration: "none" }} className="rightsideoption">
          <span className="items RouteOptions " style={webStyle.HeadingOption}>
            Home
          </span>
        </a>
        <a href="#services" style={{ textDecoration: "none" }} className="rightsideoption">
          <span className="items RouteOptions " style={webStyle.HeadingOption}>
            Services
          </span>
        </a>
        <a href="#about-us" style={{ textDecoration: "none" }} className="rightsideoption">
          <span className="items RouteOptions " style={webStyle.HeadingOption}>
            About us
          </span>
        </a>
        <a href="#gallery" style={{ textDecoration: "none" }} className="rightsideoption ">
          <span className="items RouteOptions " style={webStyle.HeadingOption}>
            Gallery
          </span>
        </a>
        <div className="dropdown rightsideoption">
          <span className="items RouteOptions " style={webStyle.HeadingOption}>
            Contact Us
          </span>
          <div className="dropdown-content">
            <span
              onClick={handleContact}
              className="items dropdownOptions"
              style={webStyle.options}
            >
              Call Us
            </span>
            <span
              className="items dropdownOptions"
              style={webStyle.options}
              onClick={HandleWhatsApp}
            >
              Whatsapp Us{" "}
            </span>
            {/* <span
              style={webStyle.options}
              className="items options"
              onClick={handleEmail}
            >
              Email Us
            </span> */}
          </div>
        </div>
      </Box>
      <Box className="item  humburger-menu rightsideoption">
        <MenuIcon
          onClick={openMenu}
          style={{ fontSize: "35px", marginRight: "20px" }}
        />
      </Box>
      {isSidebarVisible && (
        <Box className="sidebar">
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              width: "98%",
              margin: "12px",
            }}
          >
            <img
              onClick={() => history.push("/")}
              src={Logo}
              style={webStyle.logo}
              className="logo"
            />
            <CloseIcon
              onClick={() => setSidebarVisibility(false)}
              style={{ marginRight: "15px" }}
            />
          </div>

          <Box style={{ width: "100%" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                lineHeight: "2.5",
                width: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      onClick={handleHomeclick}
                      style={{
                        ...webStyle.HeadingOption,
                        fontWeight: 600,
                        borderBottom: "1px solid grey",
                        padding: "15px",
                        margin: "0",
                        color: "black",
                      }}
                    >
                      Home{" "}
                    </span>
                    <a href="#services" style={{
                        ...webStyle.HeadingOption,
                        fontWeight: 600,
                        borderBottom: "1px solid grey",
                        padding: "15px",
                        margin: "0",
                        color: "black",
                        textDecoration:"none"
                      }} onClick={handleHomeclick}
                      >
                    <span
                    
                      >
                      Services
                    </span>
                    </a>
                      <a href="#about-us" style={{
                        ...webStyle.HeadingOption,
                        fontWeight: 600,
                        borderBottom: "1px solid grey",
                        padding: "15px",
                        margin: "0",
                        color: "black",
                        textDecoration:"none"
                      }} onClick={handleHomeclick}
                      >
                    <span
                    
                      >
                      About Us
                    </span>
                    </a>

                    <a href="#gallery" style={{
                        ...webStyle.HeadingOption,
                        fontWeight: 600,
                        borderBottom: "1px solid grey",
                        padding: "15px",
                        margin: "0",
                        color: "black",
                        textDecoration:"none"
                      }} onClick={handleHomeclick}
                      >
                    <span
                    
                      >
                      Gallery
                    </span>
                    </a>
                  
                  </div>
                  <p
                    onClick={() => handleSectionClick("Contact Us")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "white",
                      justifyContent: "space-between",
                      padding: "15px",
                      border: "none",
                      borderBottom: "1px solid grey",
                      margin: "0",
                    }}
                  >
                    <div>
                      <span style={{ fontWeight: 600,fontSize:"22px" }}>Contact Us</span>
                    </div>
                    <span>
                      {openSection === "Contact Us" ? (
                        <KeyboardArrowUpIcon style={{ fontSize: "15px" }} />
                      ) : (
                        <KeyboardArrowDownIcon style={{ fontSize: "15px" }} />
                      )}
                    </span>
                  </p>
                  {openSection === "Contact Us" && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        className=" options"
                        style={webStyle.options}
                        onClick={handleContact}
                      >
                        Call Us
                      </span>
                      <span
                        className=" options"
                        style={webStyle.options}
                        onClick={HandleWhatsApp}
                      >
                        Whatsapp Us
                      </span>
                      {/* <span
                        style={webStyle.options}
                        className=" options"
                        onClick={handleEmail}
                      >
                        Email Us
                      </span> */}
                    </div>
                  )}{" "}
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Navbar;
