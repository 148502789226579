import React from "react";
import {
  Logo,
  FooterImg,
  Washing_Car,
  Car,
  Mud_Car,
  QRCode,
} from "../Assets.tsx";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useHistory } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Footer.css";
gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const history = useHistory();

  useGSAP(() => {
    gsap.from(".gsapFooterSection", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".gsapFooterSection",
        scroller: "body",
        markers: false,
        start: "top 80%",
      },
    });
  });

  useGSAP(() => {
    gsap.from(".gsapFooterSection2", {
      opacity: 0,
      duration: 2,
      x: 500,
      scrollTrigger: {
        trigger: ".gsapFooterSection2",
        scroller: "body",
        markers: false,
        start: "top 65%",
      },
    });
  });

  useGSAP(() => {
    gsap.from(".gsapFooterLogo", {
      opacity: 0,
      duration: 1,
      scale: 0.5,
      y: 20,
      delay: 1,
      scrollTrigger: {
        trigger: ".gsapFooterLogo",
        scroller: "body",
        markers: false,
        start: "top 65%",
      },
    });
  });

  const handleLinkClick = (path: string) => {
    history.push(path);
  };

  const handleContactClick = () => {
    window.location.href = "tel: +91 8087878181";
  };

  // const handleEmailClick = () => {
  //   window.location.href = "mailto:autoherb@gmail.com";
  // };

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/918087878181", "_blank");
  };

  return (
    <div
      style={{
        height: "100vh",
        background: `url(${FooterImg}) no-repeat center center/cover`,
        color: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 40px 20px",
        position: "relative",
        overflow: "hidden",
      }}
      className="footerResize"
    >
      <img
        src={Logo}
        className="gsapFooterLogo"
        alt="icon"
        style={{
          width: "181px",
          cursor: "pointer",
        }}
        onClick={() => {
          history.push("/");
        }}
      />
      <span
        className="gsapFooterSection2"
        style={{
          fontSize: "26px",
          fontWeight: 800,
          padding: "24px",
          letterSpacing: "3px",
          lineHeight: "1.3em",
          textAlign: "center",
        }}
      >
        INFINITE AUTO HERB
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          maxWidth: "1200px",
        }}
        className="FooterContainer gsapFooterSection"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
            lineHeight: 1.5,
            width: "100%",
          }}
          className="FooterSection"
        >
          <span style={{ fontSize: "21px", fontWeight: 800 }}>
            EXPLORE MORE
          </span>
          <a href="/" style={{ textDecoration: "none" }}>
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              Home
            </span>
          </a>
          <a href="#services" style={{ textDecoration: "none" }}>
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              Services
            </span>
          </a>
          <a href="#about-us" style={{ textDecoration: "none" }}>
            <span
              style={{
                fontSize: "20px",
                color: "black",
                cursor: "pointer",
              }}
            >
              About Us
            </span>
          </a>
          <a href="#gallery" style={{ textDecoration: "none" }}>
            <span
              style={{
                fontSize: "20px",
                color: "black",
                cursor: "pointer",
              }}
            >
              Gallery
            </span>
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
            lineHeight: 1.5,
            width: "100%",
          }}
          className="FooterSection"
        >
          <span style={{ fontSize: "21px", fontWeight: 800 }}>SERVICES</span>
          <a
            href="#services"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
          <span
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
            }}
          >
            Car Spa
          </span>
          </a>
          <a
            href="#services"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
          <span
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
            }}
          >
            Paint Protection Film
          </span>
          </a>
          <a
            href="#services"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
          <span
            style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
            className="RouteOptions2"
          >
            10H Ceramic Coating
          </span>
          </a>
          <a
            href="#services"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
          <span
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
            }}
          >
            Sun Control Fimling
          </span>
          </a>
          <a
            href="#services"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              Polymer Coating
            </span>
          </a>
          <a
            href="#services"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              Denting And Painting
            </span>
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
            lineHeight: 1.5,
            width: "100%",
          }}
          className="FooterSection"
        >
          <span style={{ fontSize: "21px", fontWeight: 800, color: "black" }}>
            FOLLOW US ON
          </span>
          <div>
            <a href="https://www.facebook.com/infiniteautoherb/">
              <FacebookIcon
                style={{
                  color: "black",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              />
            </a>
            <a href="https://www.instagram.com/infiniteautoherb">
              <InstagramIcon
                style={{
                  color: "black",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              />
            </a>
          </div>
          <div>
            <img src={QRCode} alt="" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          className="FooterSection"
        >
          <span
            style={{
              fontSize: "21px",
              fontWeight: 800,
              margin: "0",
              textTransform: "uppercase",
            }}
          >
            Address
          </span>
          <span
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
              textTransform: "capitalize",
            }}
          >
            Sr.No.38/2,Near Nanded city main gate,singhad road, vadgaon khurd,
            pune -411041
          </span>
          <span
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            className="contentCenter"
            onClick={handleContactClick}
          >
            <CallIcon
              onClick={handleContactClick}
              style={{ marginTop: "10px", cursor: "pointer" }}
              className="social-icon"
            />
            <WhatsAppIcon
              onClick={handleWhatsAppClick}
              style={{ marginTop: "10px", cursor: "pointer" }}
              className="social-icon"
            />
            <div style={{ marginTop: "9px", cursor: "pointer" }}>
              +91 8087878181
            </div>
          </span>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <img
          src={Washing_Car}
          alt="Washing Car"
          style={{ width: "300px", height: "260px" }}
          className="washingCar"
        />
      </div>
      <h6
        style={{
          textAlign: "center",
          position: "relative",
          top: "200px",
          color: "gray",
          fontWeight: 200,
          fontSize: "12px",
        }}
      >
        2024 © Infinite Auto Herb All rights reserved.
      </h6>
      <img src={Car} alt="Car Icon" className="car-animation" />
      <img src={Mud_Car} alt="Mud Car Icon" className="mud-car-animation" />
    </div>
  );
};

export default Footer;
