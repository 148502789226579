import React, { useEffect, useState } from "react";
import { aboutus } from "../../Assets.tsx";
import { ReactTyped } from "react-typed";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Aboutus = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutusSection h1", {
        opacity: 0,
        duration: 1,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapAboutusSection h1",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutusSection .aboutusSection", {
        opacity: 0,
        duration: 1,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapAboutusSection .aboutusSection",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutusSection .aboutImage", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapAboutusSection .aboutImage",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
  const profile = "/INFINITE AUTO HERB.pdf";

const openPDF = (link: string) => {
  window.open(link, "_blank");
};
  return (
    <div
      className="fixed-bg-AboutUsPage gsapAboutusSection"
      style={{ color: "white" }}
      id="about-us"
    >
      <h1
        style={{
          color: "white",
          paddingTop: "40px",
          fontFamily: "Rowdies, sans-serif",
        }}
      >
        <ReactTyped strings={["About Us"]} typeSpeed={100} loop />
      </h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: " 0 100px",
        }}
        className="AboutusContainer"
      >
        <div style={{ width: "50%" }} className="fullwidth aboutusSection">
          <h2>A Few Words About AutoHerb</h2>
          <p style={{ width: "90%", textAlign: "justify" }}>
            Automobiles have evolved from mere modes of transportation into
            highly coveted personal dream machines. We invest significant
            amounts of money and effort to maintain our vehicles in pristine
            condition, enhancing their comfort, durability, and aesthetics with
            the latest features. Driven by this passion, bike and car
            enthusiasts Raj Mate and Sumit Walhekar embarked on a journey that
            has culminated in one of Pune's premier car detailing and service
            brands. Infinite Auto Herb is renowned for its exceptional customer
            feedback and highest service ratings in Pune, offering exclusive car
            treatment and detailing services such as:
          </p>
          <ul style={{ textTransform: "uppercase" }}>
            <li className="rightSymbol"> CAR SPA</li>
            <li className="rightSymbol"> PAINT PROTECTION FILM</li>
            <li className="rightSymbol"> 10H CERAMIC COATING</li>
            <li className="rightSymbol"> SUN CONTROLL FILMING</li>
            <li className="rightSymbol"> POLYMER COATING</li>
            <li className="rightSymbol"> DENTING AND PAINTING</li>
          </ul>
          <div className="buttons">
            <button className="blob-btn" onClick={() => openPDF(profile)}>
              Our Profile
              <span className="blob-btn__inner">
                <span className="blob-btn__blobs">
                  <span className="blob-btn__blob"></span>
                  <span className="blob-btn__blob"></span>
                  <span className="blob-btn__blob"></span>
                  <span className="blob-btn__blob"></span>
                </span>
              </span>
            </button>
            <br />

            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    result="blur"
                    stdDeviation="10"
                  ></feGaussianBlur>
                  <feColorMatrix
                    in="blur"
                    mode="matrix"
                    values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                    result="goo"
                  ></feColorMatrix>
                  <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div style={{ width: "50%" }} className="fullwidth aboutImage">
          <img src={aboutus} style={{ width: "100%", height: "auto" }} />
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
