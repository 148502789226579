import React from "react";
import { Carousel } from "react-bootstrap";
import { Banner, Banner1, Banner2 } from "../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

interface BannerCarouselProps {
  fade: boolean;
  interval: number;
  controls: boolean;
  indicators: boolean;
}

const BannerCarousel: React.FC<BannerCarouselProps> = ({ fade, interval, controls, indicators }) => {
  useGSAP(() => {
    gsap.from(".BannerTittleGsap", {
      y: 50,
      opacity: 0,
      duration: 1,
      delay: 1,
    });
  });

  return (
    <Carousel fade={fade} interval={interval} controls={controls} indicators={indicators}>
      <Carousel.Item style={{ height: "720px" }} className="BannerImageCarousel">
        <img
          style={{ height: "720px" }}
          className="d-block w-100"
          src={Banner}
          alt="first slide"
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
          <div
            className="BannerTittleGsap"
            style={{
              fontSize: "50px",
              fontWeight: 700,
              fontStyle: "italic",
              textAlign: "center",
              position: "relative",
              bottom: "20px",
              left: "170px",
              width: "77%",
              fontFamily: "Rowdies, sans-serif",
            }}
          >
            Car & Bike  Detailing Center <br/> <span style={{fontSize:"26px"}} className="BannersubtitleGsap">Transform Your Vehicle with Auto-Herb: Innovative Care for Every Drive!</span>
          </div>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ height: "720px" }} className="BannerImageCarousel">
        <img
          style={{ height: "720px" }}
          className="d-block w-100"
          src={Banner1}
          alt="second slide"
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
        <div
            className="BannerTittleGsap"
            style={{
              fontSize: "50px",
              fontWeight: 700,
              fontStyle: "italic",
              textAlign: "center",
              position: "relative",
              bottom: "20px",
              left: "170px",
              width: "77%",
              fontFamily: "Rowdies, sans-serif",
            }}
          >
            Car & Bike  Detailing Center <br/> <span style={{fontSize:"26px"}} className="BannersubtitleGsap">Transform Your Vehicle with Auto-Herb: Innovative Care for Every Drive!</span>
          </div>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ height: "720px" }} className="BannerImageCarousel">
        <img
          style={{ height: "720px" }}
          className="d-block w-100"
          src={Banner2}
          alt="third slide"
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
        >
         <div
            className="BannerTittleGsap"
            style={{
              fontSize: "50px",
              fontWeight: 700,
              fontStyle: "italic",
              textAlign: "center",
              position: "relative",
              bottom: "20px",
              left: "170px",
              width: "77%",
              fontFamily: "Rowdies, sans-serif",
            }}
          >
            Car & Bike  Detailing Center <br/> <span style={{fontSize:"26px"}} className="BannersubtitleGsap">Transform Your Vehicle with Auto-Herb: Innovative Care for Every Drive!</span>
          </div>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default BannerCarousel;
