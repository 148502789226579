import React from 'react';
import TestimonialsCarousel from './TestimonialsCarousel.tsx';
import { ReactTyped } from 'react-typed';

const Testimonials = () => {
  const ourTeam = [
    {
    //   image: "https://www.rgaa.co.in/wp-content/uploads/2020/07/IMG_20200718_184416-1.jpg",
      rating: 5,
      name:"Mayur Thakur",
      description:"I recently did a car accessory and interior fitting at Infinite Auto Herb.Great service, attentive and polite staff as always, and very competitive pricing.It was a pleasure to discover a business that not only takes care of your vehicle, but treats its customers so nicely.",
    },
    {
    //   image: "https://www.rgaa.co.in/wp-content/uploads/2020/07/IMG_20200718_184343.jpg",
      rating: 5,
      name:"swapnil kumbhar",
      description:"Recently I did 10H ceramic coating on MH Hector. I am very happy with the work they did and delivered. I can see the difference in after the quoting. Thank you so much for this.",
    },
    {
    //   image: "https://www.rgaa.co.in/wp-content/uploads/2020/07/IMG_20200718_184610.jpg",
      rating: 5,
      name:"abhijeet andansare",
      description:"Recently did PPF for my bike. Extremely happy with the quality and service provided. Also rates were reasonable too. Perfect place for all your car & bike related accessories",
    },
    {
    //   image: "https://www.rgaa.co.in/wp-content/uploads/2020/07/IMG_20200718_184253.jpg",
      rating: 5,
      name:"Dipak Mandge",
      description:"Very good service. One stop Car solution for Car Cleaning, Denting and Painting. Also provide Car Reparing and accessories work. Owners and Staff are polite and always give some extra efforts and services during work. I am regularly visiting for my Alto Car servicing and work.",
    },
    {
    //   image: "https://www.rgaa.co.in/wp-content/uploads/2020/08/Mr.Karan-Raj.jpeg",
      rating: 5,
      name:"Sahil Mulla",
      description:"Well it's been an year nd half after I've done the ceramic coating on my bike. In this 18 months, I ride it for 18k km, almost in every season nd trust me the matt finish is as it is which it was on the day when I bought it.",
    },
    {
    //   image: "https://www.rgaa.co.in/wp-content/uploads/2020/07/IMG_20200718_184858.jpg",
      rating: 5,
      name:"Shailesh Mistry",
      description:"Excellent service and good behavior of staff. Very cooperative and knowledgeable staff . Will continue with the service.",
    },
    {
        //   image: "https://www.rgaa.co.in/wp-content/uploads/2020/07/IMG_20200718_184858.jpg",
          rating: 5,
          name:"atul walhekar",
          description:"Highly recommended for Car and bike services. I must say Car and bike coating specialist. In recently visited this auto herb they have Professional staff and owners Mr. raj and Mr. Sumit personally assist me. I found owners who truly care about their customers. I highly recommended.",
        },
  ];

  return (
    <div style={{width:"100%"}} className='fixed-bg-TestinomialPage' >
    <h1
        style={{
          color: "white",
          paddingTop: "40px",
          fontFamily: "Rowdies, sans-serif",
        }}
      >
        <ReactTyped strings={["Client Testimonial"]} typeSpeed={100} loop />
      </h1>
      <div style={{marginTop:"80px"}} >

      <TestimonialsCarousel members={ourTeam} />
      </div>
    </div>
  );
}

export default Testimonials;
