import React, { useEffect, useState } from 'react';
import './TestimonialsCarousel.css';
import IconButton from '@mui/material/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Rating from '@mui/material/Rating';
import { google } from '../../Assets.tsx';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export interface CarouselProps {
  members: {
    // image: string;
    rating: number;
    name:string;
    description:string;
  }[];
}

const TestimonialsCarousel: React.FC<CarouselProps> = ({ members }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % members.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + members.length) % members.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, []);

  const currentSlides = members.slice(currentIndex, currentIndex + 3);
  const displayedSlides = currentSlides.length < 3 ? currentSlides.concat(members.slice(0, 3 - currentSlides.length)) : currentSlides;

  const currentSlidesMobile = members.slice(currentIndex, currentIndex + 1);
  const displayedSlidesMobile = currentSlidesMobile.length < 1 ? currentSlidesMobile.concat(members.slice(0, 1 - currentSlidesMobile.length)) : currentSlidesMobile;

  useEffect(() => {
    const slideInterval = setInterval(() => {
      nextSlide();
    }, 8000);

    return () => clearInterval(slideInterval);
  }, []);

  return (
    <div className="carousel5">
      <IconButton className="prev" onClick={prevSlide}>
        <ArrowBackIos  style={{color:"white",marginRight:"20px",fontSize:"50px"}} className='ArrowHover'/>
      </IconButton>
      <div className='desktopView'>
        {displayedSlides.map((member, index) => (
<a href='https://www.google.com/search?q=infonote+auto+herb+pune&rlz=1C1UEAD_enIN1001IN1001&oq=infonote+auto+herb+pune&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDg5NDZqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x3bc295737289e029:0xea06d3fb54fae61f,1,,,,' style={{textDecoration:"none",color:"black",cursor:"pointer"}} target='_blank'>
          <div className="slide5" key={index}>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>

            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignContent:"center",width:"100%"}}>
                <img src={google} style={{width:"80px",display:"flex",alignSelf:"center"}} />
            <Rating name="read-only" value={member.rating} readOnly />
            </div>
            </div>
            <div className="member-info5" style={{display:"flex",alignItems:"center"}}>
            <div>
            {/* <img src={member.image} alt={`Slide5 ${index + 1}`} className='image5'/> */}
<AccountCircleIcon style={{fontSize:"60px"}} />
              <h3 style={{color:"black",fontSize:"18px",fontWeight:700,textTransform:"capitalize"}}>{member.name}</h3>
              <p style={{fontSize:"14px",textAlign:"justify",padding:"0 10px"}}>{member.description}</p>
            </div>
            </div>
          </div>
            </a>
        ))}
      </div>

      <div className='MobileView'>
      {displayedSlidesMobile.map((member, index) => (
        <a href='https://www.google.com/search?q=infonote+auto+herb+pune&rlz=1C1UEAD_enIN1001IN1001&oq=infonote+auto+herb+pune&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCDg5NDZqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x3bc295737289e029:0xea06d3fb54fae61f,1,,,,' style={{textDecoration:"none",color:"black",cursor:"pointer"}} target='_blank'>

          <div className="slide5" key={index}>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>

            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignContent:"center",width:"100%"}}>
                <img src={google} style={{width:"80px",display:"flex",alignSelf:"center"}} />
            <Rating name="read-only" value={member.rating} readOnly />
            </div>
            </div>
            <div className="member-info5" style={{display:"flex",alignItems:"center"}}>
            <div>
            {/* <img src={member.image} alt={`Slide5 ${index + 1}`} className='image5'/> */}
<AccountCircleIcon style={{fontSize:"60px"}} />
              <h3 style={{color:"black",fontSize:"18px",fontWeight:700,textTransform:"capitalize"}}>{member.name}</h3>
              <p style={{fontSize:"14px",textAlign:"justify",padding:"0 10px"}}>{member.description}</p>
            </div>
            </div>
          </div>
          </a>
        ))}
      </div>
      <IconButton className="next" onClick={nextSlide}>
        <ArrowForwardIos style={{color:"white",marginLeft:"20px",fontSize:"50px"}} className='ArrowHover' />
      </IconButton>
    </div>
  );
};

export default TestimonialsCarousel;
