import React from "react";
import {
  servicesImg1,
  servicesImg2,
  servicesImg3,
  servicesImg4,
  servicesImg5,
  servicesImg6,
  hoverImg1,
  hoverImg2,
  hoverImg3,
  hoverImg4,
  hoverImg5,
  hoverImg6,
} from "../Assets.tsx";
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ReactTyped } from "react-typed";

const carData = [
  {
    carId: "1",
    carName: "CAR SPA",
    carImg: servicesImg1,
    hoverImg: hoverImg1,
  },
  {
    carId: "2",
    carName: "PAINT PROTCTION PRINT (PPF)",
    carImg: servicesImg2,
    hoverImg: hoverImg2,
  },
  {
    carId: "3",
    carName: "10H CERAMIC COATING",
    carImg: servicesImg3,
    hoverImg: hoverImg3,
  },
  {
    carId: "4",
    carName: "SUN CONTROL FILMING",
    carImg: servicesImg4,
    hoverImg: hoverImg4,
  },
  {
    carId: "5",
    carName: "POLYMER COATING",
    carImg: servicesImg5,
    hoverImg: hoverImg5,
  },
  {
    carId: "6",
    carName: "DENTING AND PAINTING",
    carImg: servicesImg6,
    hoverImg: hoverImg6,
  },
];

const CardGrid = () => {
  const handleContact = () => {
    window.location.href = "tel:8087878181";
  };

  return (
    <div id="services" className="main">
      <h1 style={{marginBottom: "30px", fontFamily: "Rowdies, sans-serif",}}><ReactTyped strings={[("Our Services")]}  typeSpeed={100} loop /> </h1>
      <ul className="cards">
        {carData.map((cars, index) => (
          <li className="cards_item" key={index}>
            <div className="card">
              <div className="card_inner">
                <div className="card_front">
                  <div className="card_image">
                    <img src={cars.carImg} alt={cars.carName} />
                  </div>
                  <div className="card_content">
                    <h2 className="card_title">{cars.carName}</h2>
                    <button className="btn card_btn" onClick={handleContact}><CallIcon/> Contact Us <WhatsAppIcon/></button>
                  </div>
                </div>
                <div className="card_back">
                  <div className="card_image">
                    <img src={cars.hoverImg} alt="back card" />
                  </div>
                  <div className="card_content_back">
                    <h2 className="card_title"></h2>
                    <button className="btn1 card_btn" onClick={handleContact}><CallIcon/> Contact Us <WhatsAppIcon/></button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CardGrid;
