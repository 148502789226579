import React, { useEffect, useState } from "react";
import {
  GalleryVideo,
  GalleryVideo2,
  servicesImg1,
  servicesImg2,
  servicesImg3,
  servicesImg4,
  servicesImg5,
} from "../../Assets.tsx";
import { ReactTyped } from "react-typed";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Gallery = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useGSAP(() => {
        if (!isMobile) {
            gsap.from(".gsapGallerySection h1", {
                opacity: 0,
                duration: 1,
                x: 500,
                scrollTrigger: {
                    trigger: ".gsapGallerySection h1",
                    scroller: "body",
                    markers: false,
                    start: "top 55%",
                },
            });
        }
    });

    useGSAP(() => {
        if (!isMobile) {
            gsap.from(".gsapGallerySection .GalleryImage", {
                opacity: 0,
                duration: 1,
                scale: 0.5,
                y: 20,
                delay: 1,
                scrollTrigger: {
                    trigger: ".gsapGallerySection .GalleryImage",
                    scroller: "body",
                    markers: false,
                    start: "top 55%",
                },
            });
        }
    });

    useGSAP(() => {
        if (!isMobile) {
            gsap.from(".gsapGallerySection .Gallerysection", {
                opacity: 0,
                duration: 1,
                scale: 0.5,
                y: 20,
                delay: 1,
                scrollTrigger: {
                    trigger: ".gsapGallerySection .Gallerysection",
                    scroller: "body",
                    markers: false,
                    start: "top 55%",
                },
            });
        }
    });
  return (
    <div id="gallery" className="gsapGallerySection">
    <div className="fixed-bg-GalleryPage">
      <h1
        style={{
          color: "white",
          padding: "40px 0 20px 0",
          textOverflow: "ellipsis",
          fontFamily: "Rowdies, sans-serif",
        }}
      >
        <ReactTyped strings={[("Gallery")]}  typeSpeed={100} loop /> 
      </h1>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="GalleryContainer Gallerysection"
      >
          <div className="image-div GalleryImage">
          <a href="https://www.instagram.com/reel/C7YY6zrNZes/" target="_blank">
             <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            height: "450px",
            width: "100%",
            border:"1px solid white",
            borderRadius:"5px"
          }}
          className="GalleryVideo1 fullwidth"
        >
          <source src={GalleryVideo} type="video/mp4" />
        </video>
          <div className="hoverNameText">Click to watch video</div>
          </a>
        </div>
        <div
          style={{
            marginLeft: "5px",
            display: "flex",
            flexDirection: "column",
          }}
          className="CarSection"
        >
          <div className="image-div GalleryImage">
            <a
              href="https://www.instagram.com/reel/C7YY6zrNZes/"
              target="_blank"
            >
              <img
                src={servicesImg1}
                style={{
                  width: "300px",
                  border: "2px solid white",
                  borderRadius: "5px",
                }}
                className="fullwidth GalleryImage"
              />
            <div className="hoverNameText">Click to watch video</div>
            </a>
          </div>
          <div className="image-div GalleryImage">
            <a
              href="https://www.instagram.com/reel/C0OBQhLLdMQ/"
              target="_blank"
            >
              <img
                src={servicesImg5}
                style={{
                  width: "300px",
                  marginTop: "5px",
                  border: "2px solid white",
                  borderRadius: "5px",
                }}
                className="fullwidth GalleryImage"
              />
            <div className="hoverNameText">Click to watch video</div>
            </a>
          </div>
        </div>
        <div
          style={{
            marginLeft: "5px",
            display: "flex",
            flexDirection: "column",
          }}
          className="CarSection"
        >
          <div className="image-div GalleryImage">
          <a
              href="https://www.instagram.com/reel/C8Tuc66tNza/"
              target="_blank"
            >
              <img
                src={servicesImg3}
                style={{
                  width: "300px",
                  border: "2px solid white",
                  borderRadius: "5px",
                }}
                className="fullwidth GalleryImage"
              />
            <div className="hoverNameText">Click to watch video</div>
            </a>
          </div>

          <div className="image-div GalleryImage">
            <a
              href="https://www.instagram.com/reel/C7YVakJNp1y/"
              target="_blank"
            >
              <img
                src={servicesImg2}
                style={{
                  width: "300px",
                  marginTop: "5px",
                  border: "2px solid white",
                  borderRadius: "5px",
                }}
                className="fullwidth CarImageSize GalleryImage"
              />
            <div className="hoverNameText">Click to watch video</div>
            </a>
          </div>
        </div>
        <div className="image-div GalleryImage" style={{marginLeft:"6px"}}>
          <a href="https://www.instagram.com/reel/C7YY6zrNZes/" target="_blank">
             <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            height: "450px",
            width: "100%",
            border:"1px solid white",
            borderRadius:"5px",
            
          }}
          className="GalleryVideo1 fullwidth"
        >
          <source src={GalleryVideo2} type="video/mp4" />
        </video>
          <div className="hoverNameText">Click to watch video</div>
          </a>
        </div>
      </div>
      <div
        style={{
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
   <a href="https://www.instagram.com/infiniteautoherb/" target="_blank">

        <div className="buttons GalleryImage">
  <button className="blob-btn">
  View More
  <span className="blob-btn__inner">
      <span className="blob-btn__blobs">
        <span className="blob-btn__blob"></span>
        <span className="blob-btn__blob"></span>
        <span className="blob-btn__blob"></span>
        <span className="blob-btn__blob"></span>
      </span>
    </span>
  </button>
  <br/>

<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
    <filter id="goo">
      <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
      <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
      <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
    </filter>
  </defs>
</svg>
      </div>
      </a>
    </div>
    </div>
    </div>
  );
};

export default Gallery;
