export const Logo = require("./Assets/Logo.png");
export const Banner = require("./Assets/bannerImg.jpg");
export const Banner1 = require("./Assets/bannerImg1.jpg");
export const Banner2 = require("./Assets/bannerImg2.jpg");

export const servicesImg1 = require("../src/Assets/servicesImg1.jpg");
export const servicesImg2 = require("../src/Assets/servicesImg2.jpg");
export const servicesImg3 = require("../src/Assets/servicesImg3.jpg");
export const servicesImg4 = require("../src/Assets/servicesImg4.jpg");
export const servicesImg5 = require("../src/Assets/servicesImg5.jpg");
export const servicesImg6 = require("../src/Assets/servicesImg6.jpg");
export const FooterImg = require("../src/Assets/Footer_bg.png");
export const Car = require("../src/Assets/Car.jpg");
export const Mud_Car = require("../src/Assets/Car-mud.png");
export const Washing_Car = require("../src/Assets/Car wash service.gif");
export const QRCode = require("../src/Assets/scanner.png");


export const aboutus = require("../src/Assets/aboutus.png");
export const hoverImg1 = require("../src/Assets/hoverImg1_.png");
export const hoverImg2 = require("../src/Assets/hoverImg2_.png");
export const hoverImg3 = require("../src/Assets/hoverImg3_.png");
export const hoverImg4 = require("../src/Assets/hoverImg4_.png");
export const hoverImg5 = require("../src/Assets/hoverImg5_.png");
export const hoverImg6 = require("../src/Assets/hoverImg6_.png");
export const GalleryVideo = require("../src/Assets/video.mp4");
export const GalleryVideo2 = require("../src/Assets/video2.mp4");

export const GalleryBanner = require("../src/Assets/Gallery Banner.png");
export const google = require("../src/Assets/google icon.png");