import React from "react";
import Navbar from "./Navbar/Navbar.tsx";
import Footer from "./Footer.tsx";
import "./HomePage.css";
import Services from "./Services.tsx";
import Aboutus from "./Abouts us/Aboutus.tsx";
import Gallery from "./Gallery/Gallery.tsx";
import BannerCarousel from "../Common-Compnents/BannerCarousel.tsx";
import Testimonials from "./Testimonials/Testimonials.tsx";

const Homepage = () => {
  return (
    <div>
      <Navbar />
      <BannerCarousel fade={true} interval={3000} controls={true} indicators={true}/>
      <Services />
      <Aboutus />
      <Gallery />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Homepage;
